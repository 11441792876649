import React, { forwardRef, useImperativeHandle, useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import httpClient from '../../../../httpClient'
import { toast } from 'react-toastify'
import { fetchReportItems } from '../../../../store/userThunks'
import { addCustomerTypes } from '../../../../store/user'
import AddIcon from '@mui/icons-material/Add'
import Dropdown from '../../../../components/Dropdown'
import { customerContactRhythmsArray } from '../../../../assets/Data'
import { showNotificationPopup, closePopupNotification } from '../../../../components/NotificationPopup'
import ReportItem from '../shared/ReportItem/ReportItem'
import CustomerType from '../shared/CustomerType/CustomerType'
import DropdownValues from '../shared/DropdownValues/DropdownValues'

const CustomerDatabaseSettings = forwardRef(({ onChangeMade }, ref) => {
  const [addReportItem, setAddReportItem] = useState(false)
  const [addReportItemTitle, setAddReportItemTitle] = useState("")
  const [addReportItemType, setAddReportItemType] = useState("")
  const [addReportItemDropdownValues, setAddReportItemDropdownValues] = useState([null, null, null, null, null])
  const [customerContactRhythm, setCustomerContactRhythm] = useState("Yearly")
  const [newCustomerType, setNewCustomerType] = useState('')
  const [editingType, setEditingType] = useState(null)
  const [editingSubtype, setEditingSubtype] = useState(null)
  const [editedTypeValue, setEditedTypeValue] = useState('')

  const dispatch = useDispatch()
  const customerTypes = useSelector(state => state.user.customer_types)
  const reportItems = useSelector(state => state.user.reportItems)

  useImperativeHandle(ref, () => ({
    saveSettings
  }))

  useEffect(() => {
    fetchSettings()
    dispatch(fetchReportItems())
  }, [dispatch])

  const fetchSettings = async () => {
    try {
      const response = await httpClient.get(`${process.env.REACT_APP_API_URL}/api/settings/get`)
      setCustomerContactRhythm(response.data[0]?.default_customer_contact_rhythm || "Yearly")
    } catch (error) {
      console.error("Error fetching settings:", error)
      toast.error("Failed to load settings")
    }
  }

  const handleCustomerTypesChange = async (updatedTypes) => {
    try {
      const response = await httpClient.post(
        `${process.env.REACT_APP_API_URL}/api/settings/customer-types/update`,
        { types: updatedTypes }
      )
      if (response.status === 200) {
        dispatch(addCustomerTypes(response.data))
        onChangeMade()
      }
    } catch (error) {
      toast.error("Failed to update customer types")
    }
  }

  const handleDeleteReportItem = async (itemId) => {
    showNotificationPopup({
      title: "Delete Report Item",
      message: "Are you sure you want to delete this report item?",
      actionButtonOnClick: async () => {
        try {
          await httpClient.delete(
            `${process.env.REACT_APP_API_URL}/api/settings/custom-customer-data/delete?itemID=${itemId}`
          )
          dispatch(fetchReportItems())
          closePopupNotification()
          toast.success("Report item deleted successfully")
        } catch (error) {
          toast.error("Failed to delete report item")
          closePopupNotification()
        }
      },
      actionButtonText: "DELETE",
      popupButtonColor: "red"
    })
  }

  const saveSettings = async () => {
    try {
      const promises = [
        httpClient.post(
          `${process.env.REACT_APP_API_URL}/api/settings/change`,
          { default_customer_contact_rhythm: customerContactRhythm }
        )
      ]

      if (addReportItem && addReportItemTitle && addReportItemType) {
        promises.push(
          httpClient.post(
            `${process.env.REACT_APP_API_URL}/api/settings/custom-customer-data/create`,
            {
              title: addReportItemTitle,
              type: addReportItemType,
              dropdown_values: addReportItemType !== "dropdown" ? 
                [null, null, null, null, null] : 
                addReportItemDropdownValues
            }
          )
        )
      }

      await Promise.all(promises)
      
      setAddReportItem(false)
      setAddReportItemTitle("")
      setAddReportItemType("")
      setAddReportItemDropdownValues([null, null, null, null, null])
      
      dispatch(fetchReportItems())
      toast.success("Settings saved successfully")
      return true
    } catch (error) {
      console.error("Error saving settings:", error)
      toast.error("Failed to save settings")
      return false
    }
  }

  const handleAddCustomerType = async () => {
    if (newCustomerType && !customerTypes[newCustomerType]) {
      try {
        const response = await httpClient.post(
          `${process.env.REACT_APP_API_URL}/api/settings/customer-types/create`,
          { type_name: newCustomerType }
        )
        if (response.status === 200) {
          dispatch(addCustomerTypes(response.data))
          toast.success("Customer type added successfully")
          onChangeMade()
        }
      } catch (error) {
        toast.error("Failed to add customer type")
      }
    } else {
      toast.error('Customer type already exists')
    }
    setNewCustomerType('')
  }

  const handleAddSubtype = async (type, subtype) => {
    if (customerTypes[type] && !customerTypes[type].includes(subtype)) {
      try {
        const response = await httpClient.post(
          `${process.env.REACT_APP_API_URL}/api/settings/customer-types/create`,
          { type_name: type, subtype_name: subtype }
        )
        if (response.status === 200) {
          dispatch(addCustomerTypes(response.data))
          toast.success('Subtype added successfully')
          onChangeMade()
        }
      } catch (error) {
        toast.error('Failed to add subtype')
      }
    } else {
      toast.error('Subtype already exists')
    }
  }

  const handleEditType = async (oldType, newType) => {
    if (oldType === newType) {
      setEditingType(null)
      return
    }

    try {
      const response = await toast.promise(
        httpClient.put(`${process.env.REACT_APP_API_URL}/api/settings/customer-types`, {
          type_name: oldType,
          new_type_name: newType
        }),
        {
          pending: 'Updating customer type...',
          success: 'Customer type updated successfully',
          error: 'Failed to update customer type'
        }
      )
      if (response.status === 200) {
        dispatch(addCustomerTypes(response.data))
        onChangeMade()
      }
    } catch (error) {
      console.error('Error editing customer type:', error)
    }
    setEditingType(null)
  }

  const handleEditSubtype = async (type, oldSubtype, newSubtype) => {
    if (oldSubtype === newSubtype) {
      setEditingSubtype(null)
      return
    }

    try {
      const response = await toast.promise(
        httpClient.put(`${process.env.REACT_APP_API_URL}/api/settings/customer-types`, {
          type_name: type,
          subtype_name: oldSubtype,
          new_subtype_name: newSubtype
        }),
        {
          pending: 'Updating subtype...',
          success: 'Subtype updated successfully',
          error: 'Failed to update subtype'
        }
      )
      if (response.status === 200) {
        dispatch(addCustomerTypes(response.data))
        onChangeMade()
      }
    } catch (error) {
      console.error('Error editing subtype:', error)
    }
    setEditingSubtype(null)
  }

  const handleDeleteCustomerType = async (type) => {
    showNotificationPopup({
      title: "Are you sure?",
      message: "If you delete this customer type, all associated data will be lost.",
      actionButtonOnClick: async () => {
        try {
          const response = await toast.promise(
            httpClient.delete(`${process.env.REACT_APP_API_URL}/api/settings/customer-types?type_to_delete=${type}`),
            {
              pending: 'Deleting customer type...',
              success: 'Customer type deleted successfully',
              error: 'Failed to delete customer type'
            }
          )
          if (response.status === 200) {
            dispatch(addCustomerTypes(response.data))
            closePopupNotification()
            onChangeMade()
          }
        } catch (error) {
          console.error('Error deleting customer type:', error)
          closePopupNotification()
        }
      },
      actionButtonText: "YES",
      popupButtonColor: "red",
    })
  }

  const handleDeleteSubtype = async (type, subtype) => {
    showNotificationPopup({
      title: "Are you sure?",
      message: "If you delete this customer type, all associated data will be lost.",
      actionButtonOnClick: async () => {
        try {
          const response = await httpClient.delete(
            `${process.env.REACT_APP_API_URL}/api/settings/customer-types?type_to_delete=${type}&subtype_to_delete=${subtype}`
          )
          closePopupNotification()

          if (response.status === 200) {
            dispatch(addCustomerTypes(response.data))
            toast.success('Subtype deleted successfully')
            onChangeMade()
          } else {
            toast.error(`Failed to delete subtype: ${response.data.error}`)
          }
        } catch (error) {
          console.error('Error deleting customer type:', error)
          toast.error('An error occurred while deleting the subtype')
          closePopupNotification()
        }
      },
      actionButtonText: "YES",
      popupButtonColor: "red",
    })
  }

  const customerTypeHandlers = {
    onAddType: handleAddCustomerType,
    onAddSubtype: handleAddSubtype,
    onEditType: handleEditType,
    onEditSubtype: handleEditSubtype,
    onDeleteType: handleDeleteCustomerType,
    onDeleteSubtype: handleDeleteSubtype,
    newCustomerType,
    setNewCustomerType,
    editingType,
    setEditingType,
    editingSubtype,
    setEditingSubtype,
    editedTypeValue,
    setEditedTypeValue
  }

  const handleContactRhythmChange = (rhythm) => {
    setCustomerContactRhythm(rhythm)
    onChangeMade()
  }

  return (
    <div className="settings_details__container" id="settings_customer_database">
      <h1>CUSTOMER DATABASE</h1>
      <div className="settings_forms__wrapper">
        {/* Custom Report Items Section */}
        <div className="settings_form">
          <h2>Custom Report Items</h2>
          {reportItems && reportItems.map((item, index) => (
            <ReportItem
              key={item.item_id || index}
              item={item}
              index={index}
              onDelete={handleDeleteReportItem}
            />
          ))}
          {addReportItem && (
            <div className="report-item">
              <input
                className="add-report-item__title editing"
                placeholder="Add report item title"
                value={addReportItemTitle}
                onChange={event => {
                  setAddReportItemTitle(event.target.value)
                  onChangeMade()
                }}
              />
              <div className="type_selector">
                <h4>Type:</h4>
                <div className="flex">
                  {['input', 'checkmark', 'dropdown'].map(type => (
                    <div
                      key={type}
                      className={`pick-option-button ${addReportItemType === type ? 'selected' : ''}`}
                      onClick={() => {
                        setAddReportItemType(type)
                        onChangeMade()
                      }}
                    >
                      {type.charAt(0).toUpperCase() + type.slice(1)}
                    </div>
                  ))}
                </div>
              </div>
              {addReportItemType === "dropdown" && (
                <DropdownValues
                  dropdownValues={addReportItemDropdownValues}
                  index="new"
                  isEditing={true}
                  onValueChange={(index, value) => {
                    const newValues = [...addReportItemDropdownValues]
                    newValues[index] = value
                    setAddReportItemDropdownValues(newValues)
                    onChangeMade()
                  }}
                />
              )}
            </div>
          )}
          <button className="add--btn" onClick={() => setAddReportItem(true)}>
            <AddIcon />
          </button>
        </div>

        {/* Customer Types Section */}
        <div className="settings_form">
          <h2>Customer Types</h2>
          <CustomerType
            types={customerTypes}
            handlers={customerTypeHandlers}
          />
        </div>

        {/* Customer Contact Settings */}
        <div className="settings_form">
          <h2>Contact Settings</h2>
          <div className="customer_settings__wrapper">
            <div className="input_field">
              <label className="input_field__label">Default Contact Rhythm</label>
              <Dropdown
                listArray={customerContactRhythmsArray}
                inherit={true}
                state={customerContactRhythm}
                setState={handleContactRhythmChange}
                stateType="string"
                stateValueName="customerContactRhythm"
                marginTop={"5px"}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
})

export default CustomerDatabaseSettings 