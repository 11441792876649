import React from 'react'

const AvatarSelector = ({ selectedAvatar, onSelect }) => {
  const avatarArray = [
    "man1.png", "man2.png", "man3.png", "man4.png", "man5.png", "man6.png", "man7.png", "man8.png", "man9.png",
    "female1.png", "female2.png", "female3.png", "female4.png", "female5.png", "female6.png", "female7.png", "female8.png", "female9.png",
    "ninja.png", "panda.png", "rabbit.png", "sloth.png", "lama.png", "koala.png", "dragon.png", "dog.png", "bear.png"
  ]

  const handleSelect = (e) => {
    const imageName = e.currentTarget.getAttribute('data-image')
    onSelect(imageName)

    const avatarImages = document.querySelectorAll('.avatar_img')
    avatarImages.forEach(img => img.classList.remove('selected'))
    e.currentTarget.classList.add('selected')
  }

  return (
    <>
      <label className="input_field__label">Avatar</label>
      <div className="avatars_container">
        {avatarArray.map((avatar, index) => {
          let image
          try {
            image = require(`../../../../../assets/pics/${avatar}`)
          } catch {
            image = require(`../../../../../assets/pics/no_image_found.png`)
          }

          return (
            <img
              key={index}
              className={`avatar_img ${selectedAvatar === avatar ? 'selected' : ''}`}
              src={image}
              data-image={avatar}
              onClick={handleSelect}
              alt={`Avatar ${avatar}`}
            />
          )
        })}
      </div>
    </>
  )
}

export default AvatarSelector 