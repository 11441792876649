import React, { forwardRef, useImperativeHandle, useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { addUserInfo } from '../../../../store/user'
import httpClient from '../../../../httpClient'
import { toast } from 'react-toastify'
import AvatarSelector from '../shared/AvatarSelector/AvatarSelector'

const AccountSettings = forwardRef(({ onChangeMade }, ref) => {
  const [user, setUser] = useState({
    email: "",
    id: "",
    business_name: "",
    tel_number: "",
    delivery_address_street: "",
    delivery_address_nr: "",
    delivery_address_pc: "",
    delivery_address_place: "",
    delivery_address_country: "",
    company_name: "",
    invoice_email: "",
    VAT_number: "",
    invoice_address_street: "",
    invoice_address_nr: "",
    invoice_address_pc: "",
    invoice_address_place: "",
    invoice_address_country: "",
    first_name: "",
    last_name: "",
    image_name: ""
  })

  const dispatch = useDispatch()
  const userInfo = useSelector(state => state.user.userInfo)

  useImperativeHandle(ref, () => ({
    saveSettings
  }))
  
  const fetchUser = async () => {
    try {
      const response = await httpClient.get(process.env.REACT_APP_API_URL + "/api/@me?type=full")
      setUser(response.data)
    } catch (err) {
      console.error("Error fetching user:", err)
      toast.error("Failed to load user data")
    }
  }

  useEffect(() => {
    fetchUser()
  }, [])

  const saveSettings = async () => {
    try {
      const response = await toast.promise(
        httpClient.post(process.env.REACT_APP_API_URL + `/api/@me/update`, user),
        {
          pending: "Saving settings...",
          success: `Settings have been saved!`,
          error: "Something went wrong"
        }
      )
      
      if (response.status == 200) {
        const updatedUserInfo = { ...userInfo, image_name: response.data.image_name }
        dispatch(addUserInfo(updatedUserInfo))
      }
      
      return true
    } catch (error) {
      console.error('Error saving account settings:', error)
      toast.error('Failed to save account settings')
      return false
    }
  }

  const handleInputChange = (e) => {
    const { name, value } = e.target
    setUser(prev => ({
      ...prev,
      [name]: value
    }))
    onChangeMade()
  }

  const handleAvatarSelect = (imageName) => {
    setUser(prev => ({
      ...prev,
      image_name: imageName
    }))
    onChangeMade()
  }

  return (
    <div className="settings_details__container" id="settings_account">
      <h1>ACCOUNT DETAILS</h1>

      <div className="settings_forms__wrapper">
        {/* Contact Information */}
        <div className="settings_form">
          <h2>Contact</h2>
          <div className="input_field">
            <label className="input_field__label">Brand name</label>
            <input
              className="input_field__input_box--text"
              name="business_name"
              value={user.business_name}
              onChange={handleInputChange}
            />
          </div>
          <div className="input_field">
            <label className="input_field__label">Contact email</label>
            <input
              className="input_field__input_box--text"
              type="email"
              name="email"
              value={user.email}
              onChange={handleInputChange}
            />
          </div>
          <div className="input_field">
            <label className="input_field__label">Tel number</label>
            <input
              className="input_field__input_box--text"
              name="tel_number"
              value={user.tel_number}
              onChange={handleInputChange}
            />
          </div>
          <div className="input_field--combo-7030">
            <div className="input_field">
              <label className="input_field__label">Street</label>
              <input
                className="input_field__input_box--text"
                name="delivery_address_street"
                value={user.delivery_address_street}
                onChange={handleInputChange}
              />
            </div>
            <div className="input_field">
              <label className="input_field__label">Number</label>
              <input
                className="input_field__input_box--text"
                name="delivery_address_nr"
                value={user.delivery_address_nr}
                onChange={handleInputChange}
              />
            </div>
          </div>
          <div className="input_field--combo-3070">
            <div className="input_field">
              <label className="input_field__label">Postal code</label>
              <input
                className="input_field__input_box--text"
                name="delivery_address_pc"
                value={user.delivery_address_pc}
                onChange={handleInputChange}
              />
            </div>
            <div className="input_field">
              <label className="input_field__label">City</label>
              <input
                className="input_field__input_box--text"
                name="delivery_address_place"
                value={user.delivery_address_place}
                onChange={handleInputChange}
              />
            </div>
          </div>
          <div className="input_field">
            <label className="input_field__label">Country</label>
            <input
              className="input_field__input_box--text"
              name="delivery_address_country"
              value={user.delivery_address_country}
              onChange={handleInputChange}
            />
          </div>
        </div>

        {/* Accounting Information */}
        <div className="settings_form">
          <h2>Accounting</h2>
          <div className="input_field">
            <label className="input_field__label">Company name</label>
            <input
              className="input_field__input_box--text"
              name="company_name"
              value={user.company_name}
              onChange={handleInputChange}
            />
          </div>
          <div className="input_field">
            <label className="input_field__label">Invoice email</label>
            <input
              className="input_field__input_box--text"
              type="email"
              name="invoice_email"
              value={user.invoice_email}
              onChange={handleInputChange}
            />
          </div>
          <div className="input_field">
            <label className="input_field__label">VAT number</label>
            <input
              className="input_field__input_box--text"
              name="VAT_number"
              value={user.VAT_number}
              onChange={handleInputChange}
            />
          </div>
          <div className="input_field--combo-7030">
            <div className="input_field">
              <label className="input_field__label">Street</label>
              <input
                className="input_field__input_box--text"
                name="invoice_address_street"
                value={user.invoice_address_street}
                onChange={handleInputChange}
              />
            </div>
            <div className="input_field">
              <label className="input_field__label">Number</label>
              <input
                className="input_field__input_box--text"
                name="invoice_address_nr"
                value={user.invoice_address_nr}
                onChange={handleInputChange}
              />
            </div>
          </div>
          <div className="input_field--combo-3070">
            <div className="input_field">
              <label className="input_field__label">Postal code</label>
              <input
                className="input_field__input_box--text"
                name="invoice_address_pc"
                value={user.invoice_address_pc}
                onChange={handleInputChange}
              />
            </div>
            <div className="input_field">
              <label className="input_field__label">City</label>
              <input
                className="input_field__input_box--text"
                name="invoice_address_place"
                value={user.invoice_address_place}
                onChange={handleInputChange}
              />
            </div>
          </div>
          <div className="input_field">
            <label className="input_field__label">Country</label>
            <input
              className="input_field__input_box--text"
              name="invoice_address_country"
              value={user.invoice_address_country}
              onChange={handleInputChange}
            />
          </div>
        </div>

        {/* Personal Information */}
        <div className="settings_form">
          <h2>Personal</h2>
          <div className="input_field--combo-5050">
            <div className="input_field">
              <label className="input_field__label">First name</label>
              <input
                className="input_field__input_box--text"
                name="first_name"
                value={user.first_name}
                onChange={handleInputChange}
              />
            </div>
            <div className="input_field">
              <label className="input_field__label">Last name</label>
              <input
                className="input_field__input_box--text"
                name="last_name"
                value={user.last_name}
                onChange={handleInputChange}
              />
            </div>
          </div>
          
          <AvatarSelector
            selectedAvatar={user.image_name}
            onSelect={handleAvatarSelect}
          />
        </div>
      </div>
    </div>
  )
})

export default AccountSettings 