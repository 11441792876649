import React, { useState, useEffect } from 'react'
import httpClient from '../../../../httpClient'
import { toast } from 'react-toastify'

const OrderSettings = ({ onChangeMade }) => {
  const [settings, setSettings] = useState({
    minimum_order_value: 0,
    delivery_costs: 0,
    free_delivery_threshold: 0,
    order_confirmation_email: true,
    order_shipped_email: true
  })

  useEffect(() => {
    fetchOrderSettings()
  }, [])

  const fetchOrderSettings = async () => {
    try {
      const response = await httpClient.get(`${process.env.REACT_APP_API_URL}/api/settings/orders`)
      setSettings(response.data)
    } catch (err) {
      console.error("Error fetching order settings:", err)
      toast.error("Failed to load order settings")
    }
  }

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target
    setSettings(prev => ({
      ...prev,
      [name]: type === 'checkbox' ? checked : Number(value)
    }))
    onChangeMade()
  }

  return (
    <div className="settings_details__container">
      <h1>ORDER SETTINGS</h1>
      
      <div className="settings_forms__wrapper">
        <div className="settings_form">
          <h2>Order Requirements</h2>
          
        </div>
      </div>
    </div>
  )
}

export default OrderSettings 