import { configureStore, createAsyncThunk } from '@reduxjs/toolkit'
import { combineReducers } from "redux"
import createIndexedDBStorage from 'redux-persist-indexeddb-storage'
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
  createMigrate,
} from 'redux-persist'
import migrations from './migrations'
import userReducer from './user'
import miniMenuReducer from './miniMenu'
import notificationReducer from './notification'
import customerPageReducer from './customerPage'
import salesPageBrandOwnerReducer from './salesPageBrandOwner'
import salesPipePageReducer from './salesPipePage'
import visitReportReducer from './visitReport'
import activitiesPageReducer from './activitiesPage'
import globalReducer from './global'
import performanceReducer from './performancePage'
import productsPageReducer from './productsPage'
import notificationAlertReducer from './notificationAlert'

// Create the IndexedDB storage
const storage = createIndexedDBStorage({
  name: 'stoqup-db', // Specify a name for your IndexedDB database
  storeName: 'persist-store' // Specify a name for your object store within IndexedDB
})

const persistConfig = {
  key: 'stoqup-app',
  version: 13, // Increment version number
  storage, // Use IndexedDB storage
  migrate: createMigrate(migrations, { debug: false }),
}

const appReducer = combineReducers({
  user: userReducer,
  miniMenu: miniMenuReducer,
  notification: notificationReducer,
  customerPage: customerPageReducer,
  salesPageBrandOwner: salesPageBrandOwnerReducer,
  salesPipePage: salesPipePageReducer,
  visitReport: visitReportReducer,
  activitiesPage: activitiesPageReducer,
  global: globalReducer,
  performancePage: performanceReducer,
  productsPage: productsPageReducer,
  notificationAlert: notificationAlertReducer
})

const rootReducer = (state, action) => {
  if (action.type === 'RESET_APP_STATE') {
    const { keepUserInfo } = action.payload
    // If keepUserInfo is true, preserve the user state
    const userState = keepUserInfo ? { user: { ...state.user } } : {}
    state = userState
  }
  return appReducer(state, action)
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
})

export const persistor = persistStore(store)

export const resetAppState = (keepUserInfo = false) => {
  store.dispatch({ type: 'RESET_APP_STATE', payload: { keepUserInfo } })
}