import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from "react-redux"
import httpClient from "../httpClient"
import './PopOutWindowSettingsWarehouse.css'
import CloseIcon from '@mui/icons-material/Close'
import DropdownMultiselect from './DropdownMultiselect'
import { toast } from "react-toastify"
import { selectFilteredMyTeam } from "../store/user"

export function PopOutWindowSettingsWarehouse({ popupID, onClose, selectedWarehouse, setSelectedWarehouse }) {
  const [warehouseOwners, setWarehouseOwners] = useState([])
  const [conflicts, setConflicts] = useState()
  const [selectedWarehouseIcon, setSelectedWarehouseIcon] = useState("")

  const myTeam = useSelector(selectFilteredMyTeam)

  const iconArray = [
    "warehouse_picto_boxes.png",
    "warehouse_picto_other.png",
    "warehouse_picto_pallets.png",
    "warehouse_picto_rack_pallets.png",
    "warehouse_picto_truck.png"
  ]

  const fetchWarehouseOwners = async () => {
    const response = await httpClient.get(process.env.REACT_APP_API_URL + "/api/brandowner/warehouse/get-owners?warehouseID=" + selectedWarehouse.warehouse_id.id)
    setWarehouseOwners(response.data)
  }

  PopOutWindowSettingsWarehouse.defaultProps = {
    onClose: () => console.log(""),
    overflow: "scroll",
  }

  useEffect(() => {
    const popup = document.getElementById(popupID)
    window.addEventListener('keydown', e => {
      if (e.key === 'Escape') {
        if (popup.classList.contains("active")) {
          closePopupWindow()
        }
      }
    })
  }, [])

  useEffect(() => {
    if (selectedWarehouse) {
      fetchWarehouseOwners()
    }
  }, [selectedWarehouse])

  const closePopupWindow = () => {
    const popup = document.getElementById(popupID)
    popup.classList.remove("active")
    onClose()
  }

  const saveSettings = async (event, overwriteSettings = false) => {
    const warehouseName = document.getElementById("inventory__warehouse_name").value

    let data = selectedWarehouse.warehouse_id

    data['warehouse_owners'] = warehouseOwners

    console.log(data)

    if (!data['name']) {
      toast.error('Name is obligatory', { theme: 'dark' })
      return
    }
    const response = await toast.promise(
      (httpClient.post(process.env.REACT_APP_API_URL + "/api/brandowner/warehouse/change-settings", data)),
      {
        pending: "Updating warehouse...",
        success: `Warehouse updated!`,
        error: "Something went wrong"
      }
    )
    if (response.status === 200) {
      console.log(response.data)
      closePopupWindow()
    }
  }

  const setStateWarehouse = (e) => {
    const key = e.currentTarget.getAttribute('name')
    const value = e.currentTarget.value

    const newState = { ...selectedWarehouse }
    newState.warehouse_id[key] = value
    setSelectedWarehouse(newState)
  }

  const setState = (name, id, type = "add") => {
    if (type == "add") {
      setWarehouseOwners(preState => {
        return (
          [...preState, {
            "id": id,
            "name": name
          }]
        )
      })
    } else if (type == "remove") {
      const newState = warehouseOwners.filter(owner => owner.id != id)
      setWarehouseOwners(newState)
    }
  }

  const handleSelectIcon = (e) => {
    const thisElement = e.currentTarget
    const imageName = thisElement.getAttribute('data-image')
    const iconImages = document.querySelectorAll('.warehouse_img_parent')

    iconImages.forEach(img => img.classList.remove('selected'))
    thisElement.classList.add('selected')

    const newState = { ...selectedWarehouse }
    newState.warehouse_id["icon"] = imageName
    setSelectedWarehouse(newState)
  }

  return (
    <div className='popout__container' id={popupID}>

      <div className='popout__box' id={popupID}>

        {
          selectedWarehouse ?
            <>
              <CloseIcon id="popup__close-icon" onClick={closePopupWindow} />
              <div className="popup__content_wrapper">

                <button className="popup__options-button" id="popup__save_inbound--btn" onClick={saveSettings}>Save</button>
                <div className="form__section">
                  <h2 className="form__section_title">Warehouse name</h2>
                  <input className="input_field__input_box--text" id="inventory__warehouse_name" type="text" name="name" value={selectedWarehouse.warehouse_id.name} onChange={setStateWarehouse} />

                  <h2 className="form__section_title">address</h2>
                  <input className="input_field__input_box--text" id="new_warehouse_address" type="text" name="address" value={selectedWarehouse.warehouse_id.address} onChange={setStateWarehouse} />

                  <div className='input_field--combo-3-colmn'>
                    <div className='input_field'>
                      <h2 className="form__section_title">city</h2>
                      <input className="input_field__input_box--text" id="new_warehouse_city" type="text" name="city" value={selectedWarehouse.warehouse_id.city} onChange={setStateWarehouse} />
                    </div>
                    <div className='input_field'>
                      <h2 className="form__section_title">postal code</h2>
                      <input className="input_field__input_box--text" id="new_warehouse_zip_code" type="text" name="zip_code" value={selectedWarehouse.warehouse_id.zip_code} onChange={setStateWarehouse} />
                    </div>
                    <div className='input_field'>
                      <h2 className="form__section_title">country</h2>
                      <input className="input_field__input_box--text" id="new_warehouse_country" type="text" name="country" value={selectedWarehouse.warehouse_id.country} onChange={setStateWarehouse} />
                    </div>
                  </div>

                  <h2 className="form__section_title">Order email <p>(Orders for this warehouse will be emailed to this address)</p></h2>
                  <input className="input_field__input_box--text" id="new_warehouse_order_email" type="text" name="order_email" value={selectedWarehouse.warehouse_id.order_email} onChange={setStateWarehouse} />

                  <h2 className="form__section_title">Warehouse owners</h2>
                  <DropdownMultiselect
                    listArray={myTeam.filter(teamMember =>
                      !warehouseOwners.some(warehouseOwner => warehouseOwner?.id === teamMember?.id)
                    )}
                    state={warehouseOwners}
                    setState={setState}
                  />
                  
                  <h2 className="form__section_title">Picto</h2>
                  <div className='warehouse_pictos'>
                    {
                      iconArray.map((icon, index) => {
                        let image
                        try {
                          image = require(`../assets/pictos/${icon}`)
                        } catch {
                          image = require(`../assets/pics/image-not-found-general.png`)
                        }

                        return (
                          <div className='warehouse_img_parent' data-image={icon} key={index} onClick={handleSelectIcon}>
                            <img className="warehouse_img" src={image} />
                          </div>
                        )
                      })
                    }
                  </div>
                </div>
              </div>
            </> : null
        }

      </div>
    </div>
  )
}