import React, { forwardRef, useImperativeHandle, useState, useEffect } from "react"
import { useSelector } from "react-redux"
import { toast } from "react-toastify"
import httpClient from "../../../../httpClient"
import InputBox from "../../../../components/InputBox"

const SalesPipeSettings = forwardRef(({ onChangeMade }, ref) => {
  const [minSalesAmountStar, setMinSalesAmountStar] = useState(6)
  const [minSalesAmountStarWholesaler, setMinSalesAmountStarWholesaler] = useState(60)
  const [noSalesDaysSlow, setNoSalesDaysSlow] = useState(90)
  const [loading, setLoading] = useState(true)

  const userInfo = useSelector(state => state.user.userInfo)
  const requester = userInfo.type

  useImperativeHandle(ref, () => ({
    saveSettings
  }))

  useEffect(() => {
    fetchSettings()
  }, [])

  const fetchSettings = async () => {
    try {
      const response = await httpClient.get(process.env.REACT_APP_API_URL + `/api/settings/get`)
      setMinSalesAmountStar(response.data[0]?.star_min_order_amount)
      setMinSalesAmountStarWholesaler(response.data[0]?.star_min_order_amount_wholesaler)
      setNoSalesDaysSlow(response.data[0]?.slow_days_no_order)
    } catch (error) {
      console.log("error in fetching settings: ", error)
      toast.error("Failed to load sales pipe settings")
    }
    setLoading(false)
  }

  const handleOnChangeSetting = () => {
    onChangeMade()
  }

  const saveSettings = async () => {
    const data = {
      "star_min_order_amount": minSalesAmountStar,
      "star_min_order_amount_wholesaler": minSalesAmountStarWholesaler,
      "slow_days_no_order": noSalesDaysSlow,
    }

    try {
      const response = await toast.promise(
        httpClient.post(process.env.REACT_APP_API_URL + `/api/settings/change?requester=${requester}`, data),
        {
          pending: "Saving settings...",
          success: `Settings have been saved!`,
          error: "Something went wrong"
        }
      )
      console.log(response.data)
      fetchSettings()
      return true
    } catch (error) {
      console.error("Error saving settings:", error)
      toast.error("Failed to save sales pipe settings")
      return false
    }
  }

  if (loading) {
    return <div>Loading...</div>
  }

  return (
    <div className="settings_details__container" id="settings_sales_pipe">
      <h1>SALES PIPE</h1>
      <div className="settings_forms__wrapper">
        <div className="settings_form">
          <h2>Sales pipe settings</h2>
          <div className="settings_item">
            <InputBox
              id={"settings_item-slowlist-days"}
              text="Slow outlet: days with no orders"
              textPosition="left"
              inputBoxSize={"auto"}
              inputType="number"
              value={noSalesDaysSlow}
              setValue={setNoSalesDaysSlow}
              onChangeFunction={handleOnChangeSetting}
            />
          </div>
          <div className="settings_item">
            <InputBox
              id={"settings_item-starlist-min-order-amount"}
              text="Star outlet: minimum sales amount (items/month)"
              textPosition="left"
              inputBoxSize={"auto"}
              inputType="number"
              value={minSalesAmountStar}
              setValue={setMinSalesAmountStar}
              onChangeFunction={handleOnChangeSetting}
            />
          </div>
          <div className="settings_item">
            <InputBox
              id={"settings_item-starlist-min-order-amount-wholesalers"}
              text="Star outlet: minimum sales amount (items/month) suppliers"
              textPosition="left"
              inputBoxSize={"auto"}
              inputType="number"
              value={minSalesAmountStarWholesaler}
              setValue={setMinSalesAmountStarWholesaler}
              onChangeFunction={handleOnChangeSetting}
            />
          </div>
        </div>
      </div>
    </div>
  )
})

export default SalesPipeSettings 