import React from 'react'

const DropdownValues = ({ dropdownValues, index, isEditing, onValueChange }) => {
  return (
    <div className="dropdown_values">
      {Array.from({ length: 5 }, (_, dropdownIndex) => {
        return isEditing ? (
          <input
            key={`${index}-${dropdownIndex}`}
            className="dropdown_value"
            placeholder="Dropdown value"
            value={dropdownValues[dropdownIndex] || ''}
            onChange={(e) => onValueChange(dropdownIndex, e.target.value)}
          />
        ) : (
          dropdownValues[dropdownIndex] != null && (
            <div key={`${index}-${dropdownIndex}`} className="dropdown_value">
              {dropdownValues[dropdownIndex]}
            </div>
          )
        )
      })}
    </div>
  )
}

export default DropdownValues 