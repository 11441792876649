import React from 'react'
import AddIcon from '@mui/icons-material/Add'
import DeleteOutline from "@mui/icons-material/DeleteOutline"
import DeleteIcon from '@mui/icons-material/Delete'

const CustomerType = ({ types = {}, handlers }) => {
  const {
    onAddType,
    onAddSubtype,
    onEditType,
    onEditSubtype,
    onDeleteType,
    onDeleteSubtype,
    newCustomerType,
    setNewCustomerType,
    editingType,
    setEditingType,
    editingSubtype,
    setEditingSubtype,
    editedTypeValue,
    setEditedTypeValue
  } = handlers

  return (
    <div className="customer_types__wrapper">
      {/* Add new customer type */}
      <div className="add-customer-type">
        <input
          className="input_field__input_box--text"
          placeholder="New customer type"
          value={newCustomerType}
          onChange={(e) => setNewCustomerType(e.target.value)}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              onAddType()
            }
          }}
        />
        <button className="add--btn" onClick={onAddType}>
          <AddIcon />
        </button>
      </div>

      {/* Existing customer types display */}
      {Object.entries(types).map(([type, subtypes]) => (
        <div key={type} className="customer_type">
          {editingType === type ? (
            <input
              className="input_field__input_box--text editing"
              value={editedTypeValue}
              onChange={(e) => setEditedTypeValue(e.target.value)}
              onBlur={() => onEditType(type, editedTypeValue)}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  onEditType(type, editedTypeValue)
                }
              }}
              autoFocus
            />
          ) : (
            <h4 onClick={() => {
              setEditingType(type)
              setEditedTypeValue(type)
            }}>{type}</h4>
          )}
          <div className="action__buttons">
            <button 
              className="mini_action__buttons--delete" 
              onClick={() => onDeleteType(type)}
            >
              <DeleteOutline />
            </button>
          </div>
          <div className="subtypes">
            {subtypes.map(subtype => (
              <div key={subtype} className="subtype">
                {editingSubtype === subtype ? (
                  <input
                    className="input_field__input_box--text editing"
                    value={editedTypeValue}
                    onChange={(e) => setEditedTypeValue(e.target.value)}
                    onBlur={() => onEditSubtype(type, subtype, editedTypeValue)}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') {
                        onEditSubtype(type, subtype, editedTypeValue)
                      }
                    }}
                    autoFocus
                  />
                ) : (
                  <span onClick={() => {
                    setEditingSubtype(subtype)
                    setEditedTypeValue(subtype)
                  }}>{subtype}</span>
                )}
                <button 
                  className="delete-btn" 
                  onClick={() => onDeleteSubtype(type, subtype)}
                >
                  <DeleteIcon />
                </button>
              </div>
            ))}
            <div className="add-subtype">
              <input
                className="input_field__input_box--text"
                placeholder="New subtype"
                onKeyDown={(e) => {
                  if (e.key === 'Enter' && e.target.value) {
                    onAddSubtype(type, e.target.value)
                    e.target.value = ''
                  }
                }}
              />
              <button className="add--btn" onClick={(e) => {
                const input = e.currentTarget.previousSibling
                if (input.value) {
                  onAddSubtype(type, input.value)
                  input.value = ''
                }
              }}>
                <AddIcon />
              </button>
            </div>
          </div>
        </div>
      ))}
    </div>
  )
}

export default CustomerType 