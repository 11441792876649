import React, { useState, useEffect } from 'react'
import httpClient from '../httpClient'
import { toast } from "react-toastify"
import './PopOutWindowExportCustomers.css'
import CloseIcon from '@mui/icons-material/Close'
import { SiMailchimp } from "react-icons/si"
import { RiFileExcel2Line } from "react-icons/ri"
import CircularProgress from '@mui/material/CircularProgress'

function PopOutWindowExportCustomers({ maxHeight, isActive, setIsActive, width,
  handleExportCustomers }) {
  const [exportToMailChimp, setExportToMailChimp] = useState(false)
  const [mailChimpGroups, setMailChimpGroups] = useState([])
  const [mailChimpTags, setMailChimpTags] = useState([])
  const [loadingMailChimp, setLoadingMailChimp] = useState(true)
  const [selectedGroup, setSelectedGroup] = useState(null)
  const [selectedTags, setSelectedTags] = useState([])
  const [loadingExport, setLoadingExport] = useState(false)

  const fetchMailChimpGroups = async () => {
    const response = await httpClient.get(process.env.REACT_APP_API_URL + "/api/mailchimp/get-groups")
    if (response.status == 200) {
      setMailChimpGroups(response.data.groups)
    } else {
      toast.error("Error fetching MailChimp groups")
    }
  }

  const fetchMailChimpTags = async () => {
    const response = await httpClient.get(process.env.REACT_APP_API_URL + "/api/mailchimp/get-tag-list")
    if (response.status == 200) {
      setMailChimpTags(response.data.tags)
    } else {
      toast.error("Error fetching MailChimp tags")
    }
  }

  useEffect(() => {
    window.addEventListener('keydown', e => {
      if (e.key === 'Escape') {
        setIsActive(false)
      }
    })
  }, [])

  useEffect(() => {
    if (exportToMailChimp) {
      const fetchMailChimpData = async () => {
        setLoadingMailChimp(true)
        try {
          await Promise.all([
            fetchMailChimpGroups(),
            fetchMailChimpTags()
          ])
        } catch (error) {
          console.error('Error fetching MailChimp data:', error)
        } finally {
          setLoadingMailChimp(false)
        }
      }

      fetchMailChimpData()
    }
  }, [exportToMailChimp])

  const closePopupWindow = () => {
    setIsActive(false)
    setExportToMailChimp(false)
    setSelectedGroup(null)
  }

  const handleTagClick = (tag) => {
    setSelectedTags(prevTags => {
      if (prevTags.some(t => t.id === tag.id)) {
        return prevTags.filter(t => t.id !== tag.id);
      } else {
        return [...prevTags, tag];
      }
    })
  }

  const handleExportToMailChimp = async () => {
    setLoadingExport(true)
    const data = await handleExportCustomers("mailchimp")
    console.log(data)

    try {
      const response = await httpClient.post(
        `${process.env.REACT_APP_API_URL}/api/mailchimp/export`,
        {
          members: data,
          group: selectedGroup?.id,
          tags: selectedTags.map(tag => tag.name)
        }
      )
      toast.success("MailChimp export successful")
    } catch (error) {
      toast.error("MailChimp export failed", { theme: "dark" })
    }

    setLoadingExport(false)
    closePopupWindow()
  }

  return (
    <div
      className={`popout__container ${isActive ? "active" : ""}`}
      id={"export-popup"}
      onClick={closePopupWindow}
    >
      <div
        className='popout__box'
        style={{ maxHeight, width }}
        id={"export-popup"}
        onClick={e => e.stopPropagation()}
      >
        <CloseIcon id="popup__close-icon" onClick={closePopupWindow} />
        <div>
          <h1>Export</h1>
          <div className="export-popup__content">
            <div className="export-popup__content--buttons">
              <button className="options-button-header high-contrast medium_small" onClick={() => { handleExportCustomers("excel"); setIsActive(false) }}>Excel <RiFileExcel2Line /></button>
              <button className="options-button-header high-contrast medium_small" onClick={() => setExportToMailChimp(true)}>MailChimp <SiMailchimp /></button>
            </div>
            <div className="export-popup__content--info">
              {
                exportToMailChimp &&
                <div>
                  {
                    loadingMailChimp ?
                      <div className="loading-spinner-container">
                        <CircularProgress color="inherit" />
                      </div> :
                      <>
                        <h2>Add to group</h2>
                        <div className="groups-container">
                          {
                            mailChimpGroups &&
                            mailChimpGroups.map(group => (
                              group.interests.map(interest => (
                                <div
                                  key={interest.id}
                                  className={`interest-container ${selectedGroup?.id === interest.interest_id ? 'selected' : ''}`}
                                  onClick={() => setSelectedGroup({ id: interest.interest_id, name: interest.name })}
                                  disabled={loadingExport}
                                >
                                  {interest.name}
                                </div>
                              ))
                            ))
                          }
                        </div>
                        <h2>Add tags</h2>
                        <div className="groups-container">
                          {
                            mailChimpTags &&
                            mailChimpTags
                            .filter(tag => tag.name !== "STOQUP")
                            .map(tag => (
                              <div
                                key={tag.id}
                                className={`interest-container ${selectedTags.some(t => t.id === tag.id) ? 'selected' : ''}`}
                                onClick={() => handleTagClick(tag)}
                                disabled={loadingExport}
                              >
                                {tag.name}
                              </div>
                            ))
                          }
                        </div>
                        <button className="options-button-header full-width high-contrast" onClick={handleExportToMailChimp} disabled={loadingExport}>
                          {loadingExport ? <CircularProgress color="inherit" size={15} /> : "Export to MailChimp"}
                        </button>
                      </>
                  }
                </div>
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PopOutWindowExportCustomers

PopOutWindowExportCustomers.defaultProps = {
  onClose: () => console.log(""),
  overflow: "scroll",
  maxHeight: "75%",
  width: "50%"
}
