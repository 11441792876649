import React, { useState } from 'react'
import EditIcon from '@mui/icons-material/Edit'
import DeleteOutline from "@mui/icons-material/DeleteOutline"
import DropdownValues from '../DropdownValues/DropdownValues'
import httpClient from '../../../../../httpClient'
import { useDispatch } from 'react-redux'
import { fetchReportItems } from '../../../../../store/userThunks'
import { toast } from 'react-toastify'

const ReportItem = ({ item, index, onDelete }) => {
  const [editedItem, setEditedItem] = useState(item)
  const [isEditing, setIsEditing] = useState(false)
  const dispatch = useDispatch()

  const toggleEdit = (state) => {
    setIsEditing(state)
  }

  const handleHover = (event, type) => {
    const target = event.currentTarget
    switch (type) {
      case "over":
        target.classList.add("hover")
        break
      case "out":
        target.classList.remove("hover")
        break
    }
  }

  const handleInputChange = (key, value) => {
    setEditedItem({ ...editedItem, [key]: value })
  }

  const handleDropdownChange = (index, value) => {
    const currentArray = [...editedItem.dropdown_values]
    currentArray[index] = value
    setEditedItem({ ...editedItem, dropdown_values: currentArray })
  }

  const handleSave = async () => {
    try {
      const updateData = {
        item_id: editedItem.item_id,
        title: editedItem.title,
        type: editedItem.type,
        dropdown_values: editedItem.dropdown_values || [null, null, null, null, null]
      };

      await toast.promise(
        httpClient.put(
          `${process.env.REACT_APP_API_URL}/api/settings/custom-customer-data/update`, 
          updateData
        ),
        {
          pending: "Updating report item...",
          success: "Report item updated successfully",
          error: "Failed to update report item"
        }
      );
      dispatch(fetchReportItems());
      toggleEdit(false);
    } catch (error) {
      console.error('Error updating report item:', error);
      toast.error("Failed to update report item");
    }
  };

  const handleCancel = () => {
    setEditedItem(item)
    toggleEdit(false)
  }

  return (
    <div className="report-item">
      {isEditing ? (
        <input
          className="add-report-item__title editing"
          value={editedItem.title}
          onChange={(e) => handleInputChange('title', e.target.value)}
        />
      ) : (
        <div className="add-report-item__title">{item.title}</div>
      )}
      
      <div className="type_selector">
        <h4>Type:</h4>
        <div className="flex">
          {['input', 'checkmark', 'dropdown'].map(type => (
            <div
              key={type}
              className={`pick-option-button ${isEditing ? 
                (editedItem.type === type ? 'selected' : '') : 
                (item.type === type ? 'selected' : '')}`}
              onClick={isEditing ? (e) => handleInputChange('type', type) : undefined}
              onMouseOver={isEditing ? (event) => handleHover(event, "over") : undefined}
              onMouseLeave={isEditing ? (event) => handleHover(event, "out") : undefined}
            >
              {type.charAt(0).toUpperCase() + type.slice(1)}
            </div>
          ))}
        </div>
      </div>

      {editedItem.type === "dropdown" && (
        <DropdownValues
          dropdownValues={editedItem.dropdown_values}
          index={index}
          isEditing={isEditing}
          onValueChange={handleDropdownChange}
        />
      )}

      <div className="action__buttons">
        {isEditing ? (
          <>
            <button className="mini_action__buttons--save" onClick={handleSave}>Save</button>
            <button className="mini_action__buttons--delete" onClick={handleCancel}>Cancel</button>
          </>
        ) : (
          <>
            <button className="mini_action__buttons--edit" onClick={() => toggleEdit(true)}><EditIcon /></button>
            <button className="mini_action__buttons--delete" onClick={() => onDelete(item.item_id)}><DeleteOutline /></button>
          </>
        )}
      </div>
    </div>
  )
}

export default ReportItem 