import React, {useState, useEffect} from "react"
import httpClient from "../httpClient"
import { useSelector, useDispatch } from "react-redux"
import './CustomerDuplicatesPage.css'
import Header from "../components/Header"
import MenuBar from "../components/MenuBar"

function CustomerDuplicatesPage() {
    const [user, setUser] = useState({email: "", id: ""})
    const [error, setError] = useState(false)
    const [loading, setLoading] = useState(true)

    const dispatch = useDispatch()

    const userInfo = useSelector(state => state.user.userInfo)

    const fetchUser = async () => {
        const responseUser = await httpClient.get(process.env.REACT_APP_API_URL + "/api/@me?type=full")
        setUser(responseUser.data)
      }
    
    
      useEffect(() => {
        fetchUser()
          .catch( err => {
            console.log(err)
            setError(true)
          }) 

      }, [])


    return (
        <div className="template">
        {error ? window.location.href = "/" : null}
  
        <Header/>
        <MenuBar/>
        <div className="template__body">
          <div className="body__customer-duplicates">
            <div className="body__section" id="customer-duplicates-section"></div>
          </div>
        </div>
      </div>
    )
}

export default CustomerDuplicatesPage