import React from 'react'

const MenuSelector = ({ userType, selectedMenu, onMenuSelect }) => {
  const managerMenuItems = [
    "ACCOUNT",
    // "ORDERS",
    "SALES PIPE",
    "ACTIVITIES",
    "CUSTOMER DATABASE",
    "INTEGRATIONS"
  ]

  const salesforceMenuItems = [
    "ACCOUNT",
    "ACTIVITIES"
  ]

  const menuItems = userType === "manager" ? managerMenuItems : salesforceMenuItems

  const handleClick = (event) => {
    const menuType = event.currentTarget.innerHTML
    onMenuSelect(menuType)

    const menuButtons = document.querySelectorAll(".tertiairy_menu_title")
    menuButtons.forEach(button => {
      button.classList.remove("selected")
    })
    event.currentTarget.classList.add("selected")
  }

  return (
    <div className="body__section">
      {menuItems.map((item) => (
        <button
          key={item}
          className={`tertiairy_menu_title ${selectedMenu === item ? 'selected' : ''}`}
          onClick={handleClick}
        >
          {item}
        </button>
      ))}
    </div>
  )
}

export default MenuSelector 