import React, { useState, useEffect, useRef } from "react"
import { useSelector } from "react-redux"
import { useLocation } from 'react-router-dom'
import { toast } from "react-toastify"
import httpClient from "../../httpClient"

// Component imports
import Header from "../../components/Header"
import MenuBar from "../../components/MenuBar"
import MenuSelector from "./components/MenuSelector/MenuSelector"
import AccountSettings from "./components/AccountSettings/AccountSettings"
import OrderSettings from "./components/OrderSettings/OrderSettings"
import SalesPipeSettings from "./components/SalesPipeSettings/SalesPipeSettings"
import ActivitiesSettings from "./components/ActivitiesSettings/ActivitiesSettings"
import CustomerDatabaseSettings from "./components/CustomerDatabaseSettings/CustomerDatabaseSettings"
import IntegrationSettings from "./components/IntegrationSettings/IntegrationSettings"

import './SettingsPage.css'

function SettingsPage() {
  const [error, setError] = useState(false)
  const [menuType, setMenuType] = useState("ACCOUNT")
  const [changesMade, setChangesMade] = useState(false)
  
  const userInfo = useSelector(state => state.user.userInfo)
  const location = useLocation()

  // Add refs to access child component methods
  const accountSettingsRef = useRef()
  const orderSettingsRef = useRef()
  const salesPipeSettingsRef = useRef()
  const activitiesSettingsRef = useRef()
  const customerDatabaseSettingsRef = useRef()
  const integrationSettingsRef = useRef()

  const handleClickMenu = (menuType) => {
    setMenuType(menuType)
  }

  const handleChangeMade = () => {
    setChangesMade(true)
  }

  const saveSettings = async () => {
    try {
      let success = true
      
      // Call save method on active component based on menuType
      switch (menuType) {
        case "ACCOUNT":
          success = await accountSettingsRef.current?.saveSettings()
          break
        case "ORDERS":
          success = await orderSettingsRef.current?.saveSettings()
          break
        case "SALES PIPE":
          success = await salesPipeSettingsRef.current?.saveSettings()
          break
        case "ACTIVITIES":
          success = await activitiesSettingsRef.current?.saveSettings()
          break
        case "CUSTOMER DATABASE":
          success = await customerDatabaseSettingsRef.current?.saveSettings()
          break
        case "INTEGRATIONS":
          success = await integrationSettingsRef.current?.saveSettings()
          break
      }

      if (success) {
        setChangesMade(false)
      }
    } catch (error) {
      toast.error('Failed to save settings')
      console.error('Error saving settings:', error)
    }
  }

  const renderSettingsContent = () => {
    const commonProps = {
      onChangeMade: handleChangeMade,
      location: location,
    }

    switch (menuType) {
      case "ACCOUNT":
        return <AccountSettings ref={accountSettingsRef} {...commonProps} />
      case "ORDERS":
        return <OrderSettings ref={orderSettingsRef} {...commonProps} />
      case "SALES PIPE":
        return <SalesPipeSettings ref={salesPipeSettingsRef} {...commonProps} />
      case "ACTIVITIES":
        return <ActivitiesSettings ref={activitiesSettingsRef} {...commonProps} />
      case "CUSTOMER DATABASE":
        return <CustomerDatabaseSettings ref={customerDatabaseSettingsRef} {...commonProps} />
      case "INTEGRATIONS":
        return <IntegrationSettings ref={integrationSettingsRef} {...commonProps} />
      default:
        return null
    }
  }

  return (
    <div className="template">
      {error && (window.location.href = "/")}

      <Header>
        <button 
          className={`green-button-header ${changesMade ? "" : "hide"}`} 
          onClick={saveSettings}
        >
          SAVE
        </button>
      </Header>

      <MenuBar />
      
      <div className="template__body">
        <div className="body__settings">
          <MenuSelector 
            userType={userInfo.type}
            selectedMenu={menuType}
            onMenuSelect={handleClickMenu}
          />

          <div className="body__section" id="body-section-settings">
            {renderSettingsContent()}
          </div>
        </div>
      </div>
    </div>
  )
}

export default SettingsPage 