import { createAsyncThunk } from '@reduxjs/toolkit'
import httpClient from '../httpClient'

export const fetchMyTeam = createAsyncThunk(
  'user/fetchMyTeam',
  async (_, { getState, rejectWithValue }) => {
    const { user } = getState()
    
    // Check if user is logged in and of the correct type
    if (!user.userInfo.id) {
      return rejectWithValue("User not authorized to fetch my team")
    }

    try {
      const response = await httpClient.get(process.env.REACT_APP_API_URL + `/api/brandowner/myteam?type=full`)
      const myTeamNamesArray = response.data
      return myTeamNamesArray
    } catch (error) {
      console.error('Error fetching myTeam:', error)
      return rejectWithValue(error.message || 'Failed to fetch my team')
    }
  }
)

export const fetchSuppliers = createAsyncThunk(
  'user/fetchSuppliers',
  async (_, { getState, rejectWithValue }) => {
    const { user } = getState()
    
    // Check if user is logged in and of the correct type
    if (!user.userInfo.id || !["wholesaler"].includes(user.userInfo.app_version)) {
      return rejectWithValue("User not authorized to fetch suppliers")
    } 

    try {
      const response = await httpClient.get(process.env.REACT_APP_API_URL + "/api/suppliers/get")
      return response.data
    } catch (error) {
      console.error('Error fetching suppliers:', error)
      return rejectWithValue(error.message)
    }
  }
)

export const fetchReportItems = createAsyncThunk(
  'user/fetchReportItems',
  async () => {
    try {
      const response = await httpClient.get(`${process.env.REACT_APP_API_URL}/api/settings/custom-customer-data/get`)
      return response.data
    } catch (error) {
      throw error
    }
  }
)
