import React, { forwardRef, useImperativeHandle, useState, useEffect } from 'react'
import { toast } from 'react-toastify'
import httpClient from '../../../../httpClient'
import Dropdown from '../../../../components/Dropdown'
import GoogleIcon from '@mui/icons-material/Google'
import SalesforceIcon from '../../../../assets/pictos/svgs/SalesforceIcon'

const IntegrationSettings = forwardRef(({ onChangeMade }, ref) => {
  const [addAPIData, setAddAPIData] = useState({
    serviceProvider: "",
    api_key: "",
    api_secret: "",
    brand_name: "",
    shop_name: ""
  })
  const [apiServices, setApiServices] = useState([])
  const [googleAuthStatus, setGoogleAuthStatus] = useState(false)
  const [salesforceAuthStatus, setSalesforceAuthStatus] = useState(false)

  useImperativeHandle(ref, () => ({
    saveSettings
  }))

  useEffect(() => {
    fetchAPIs()
    checkGoogleAuthStatus()
    checkSalesforceAuthStatus()
  }, [])

  const fetchAPIs = async () => {
    try {
      const response = await httpClient.get(process.env.REACT_APP_API_URL + "/api/settings/apis/get")
      setApiServices(response.data)
    } catch (error) {
      console.error('Error fetching APIs:', error)
      toast.error('Failed to load API services')
    }
  }

  const checkGoogleAuthStatus = async () => {
    try {
      const response = await httpClient.get(`${process.env.REACT_APP_API_URL}/api/google/auth/status`)
      setGoogleAuthStatus(response.data.isConnected)
    } catch (error) {
      console.error('Failed to check Google auth status:', error)
    }
  }

  const checkSalesforceAuthStatus = async () => {
    try {
      const response = await httpClient.get(`${process.env.REACT_APP_API_URL}/api/salesforce/auth/status`)
      setSalesforceAuthStatus(response.data.isConnected)
    } catch (error) {
      console.error('Failed to check Salesforce auth status:', error)
    }
  }

  const handleInputChange = (e) => {
    const { name, value } = e.target
    setAddAPIData(prev => ({
      ...prev,
      [name]: value
    }))
    onChangeMade()
  }

  const handleGoogleConnect = async () => {
    try {
      const response = await httpClient.get(`${process.env.REACT_APP_API_URL}/api/google/auth/url`)
      window.location.href = response.data.authUrl
    } catch (error) {
      toast.error('Failed to connect to Google Calendar')
      console.error('Google Calendar connection error:', error)
    }
  }

  const handleGoogleDisconnect = async () => {
    try {
      await httpClient.post(`${process.env.REACT_APP_API_URL}/api/google/auth/disconnect`)
      setGoogleAuthStatus(false)
      toast.success('Disconnected from Google Calendar')
    } catch (error) {
      toast.error('Failed to disconnect from Google Calendar')
      console.error('Google Calendar disconnection error:', error)
    }
  }

  const handleSalesforceConnect = async () => {
    try {
      const response = await httpClient.get(`${process.env.REACT_APP_API_URL}/api/salesforce/auth/url`)
      window.location.href = response.data.authUrl
    } catch (error) {
      console.error('Error initiating Salesforce connection:', error)
      toast.error('Failed to connect to Salesforce')
    }
  }

  const handleSalesforceDisconnect = async () => {
    try {
      await httpClient.post(`${process.env.REACT_APP_API_URL}/api/salesforce/auth/disconnect`)
      setSalesforceAuthStatus(false)
      toast.success('Disconnected from Salesforce')
    } catch (error) {
      console.error('Error disconnecting from Salesforce:', error)
      toast.error('Failed to disconnect from Salesforce')
    }
  }

  const saveSettings = async () => {
    if (Object.values(addAPIData).some(value => !value)) {
      toast.error('Please complete all fields', { theme: "dark" })
      return false
    }

    if (["Geru", "Webship"].includes(addAPIData.serviceProvider) &&
      (!addAPIData.brand_name || !addAPIData.shop_name)
    ) {
      toast.error('Please complete all fields', { theme: "dark" })
      return false
    }

    try {
      const response = await toast.promise(
        httpClient.post(`${process.env.REACT_APP_API_URL}/api/settings/apis/add`, addAPIData),
        {
          pending: "Saving settings...",
          success: `Settings have been saved!`,
          error: "Something went wrong"
        }
      )

      setAddAPIData({
        serviceProvider: "",
        api_key: "",
        api_secret: "",
        brand_name: "",
        shop_name: ""
      })

      await fetchAPIs()
      return true
    } catch (error) {
      console.error('Error saving API settings:', error)
      return false
    }
  }

  return (
    <div className="settings_details__container" id="settings_apis">
      <h1>INTEGRATIONS</h1>

      <div className="settings_forms__wrapper">
        <div className="settings_form">
          <h2>Add API Service</h2>
          <div className="input_field">
            <label className="input_field__label">Service provider</label>
            <Dropdown
              listArray={["Webship", "Geru", "Salesforce"]}
              inherit={true}
              state={addAPIData}
              setState={setAddAPIData}
              stateType="object"
              stateValueName="serviceProvider"
              marginTop={"5px"}
            />
          </div>

          {["Geru", "Webship"].includes(addAPIData.serviceProvider) && (
            <>
              <div className="input_field">
                <label className="input_field__label">Brand name</label>
                <input
                  className="input_field__input_box--text"
                  name="brand_name"
                  value={addAPIData.brand_name}
                  onChange={handleInputChange}
                />
              </div>
              <div className="input_field">
                <label className="input_field__label">Shop name</label>
                <input
                  className="input_field__input_box--text"
                  name="shop_name"
                  value={addAPIData.shop_name}
                  onChange={handleInputChange}
                />
              </div>
              <div className="input_field">
                <label className="input_field__label">API Key</label>
                <input
                  className="input_field__input_box--text"
                  name="api_key"
                  value={addAPIData.api_key}
                  onChange={handleInputChange}
                />
              </div>
              <div className="input_field">
                <label className="input_field__label">API Secret</label>
                <input
                  className="input_field__input_box--text"
                  name="api_secret"
                  value={addAPIData.api_secret}
                  onChange={handleInputChange}
                />
              </div>
            </>
          )}

          {addAPIData.serviceProvider === "Salesforce" && (
            <div className="salesforce-connection">
              {salesforceAuthStatus ? (
                <div className="connection-status">
                  <span className="connected-text">✓ Connected to Salesforce</span>
                  <button
                    className="disconnect-btn"
                    onClick={handleSalesforceDisconnect}
                  >
                    Disconnect
                  </button>
                </div>
              ) : (
                <button
                  className="salesforce-btn"
                  onClick={handleSalesforceConnect}
                >
                  <SalesforceIcon /> Connect Salesforce
                </button>
              )}
            </div>
          )}
        </div>

        <div className="settings_form">
          <h2>My API Services</h2>
          <div className="api_services__wrapper">
            {apiServices.map((api, index) => (
              <div className="api_service" key={index}>
                <h4>{api.serviceProvider}</h4>
                <label>API KEY</label>
                <div className="api_key">{api.api_key}</div>
                <label>API SECRET</label>
                <div className="api_key">{api.api_secret}</div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
})

export default IntegrationSettings 