import React, { useState, useEffect } from "react"
import httpClient from "../httpClient"
import { useSelector, useDispatch } from "react-redux"
import './SalesforcePageBrandOwnerMyTeam.css'
import Header from "../components/Header"
import MenuBar from "../components/MenuBar"
import ComingSoonOverlay from "../components/placeholders/ComingSoonOverlay"
import { NotificationTop, showNotification } from "../components/NotificationTop"
import { changeNotificationMessage } from "../store/notification"
import LoadingSpinner from "../components/placeholders/LoadingSpinner"
import { NotificationPopup, showNotificationPopup, closePopupNotification } from "../components/NotificationPopup"
import Slider from "../components/Slider"
import { toast } from "react-toastify"
import { fetchMyTeam } from "../store/userThunks"
import { toggleHideInactiveTeamMembers } from "../store/user"
import { selectFilteredMyTeam } from "../store/user"
import Dropdown from "../components/Dropdown"

function SalesforcePageBrandOwnerMyTeam() {
  const [user, setUser] = useState({ email: "", id: "" })
  const [error, setError] = useState(false)
  const [loading, setLoading] = useState(true)
  const [tableBig, setTableBig] = useState(true)
  const [emailError, setEmailError] = useState("Not a valid email address")
  const [type, setType] = useState("Salesforce")
  const [prices, setPrices] = useState({})
  const [popupTitle, setPopupTitle] = useState("")
  const [popupMessage, setPopupMessage] = useState("")
  const [popupButton, setPopupButton] = useState("")
  const [popupButtonFunction, setPopupButtonFunction] = useState()

  const dispatch = useDispatch()

  const userInfo = useSelector(state => state.user.userInfo)
  const noAccess = require('../assets/pictos/no-access.png')
  const myTeamRedux = useSelector(selectFilteredMyTeam)
  const myTeamStatus = useSelector(state => state.user.myTeamStatus)
  const myTeamHideInactive = useSelector(state => state.user.myTeamHideInactive)

  const fetchUser = async () => {
    const responseUser = await httpClient.get(process.env.REACT_APP_API_URL + "/api/@me?type=full")
    setUser(responseUser.data)
  }

  const fetchPrices = async () => {
    const responsePrices = await httpClient.get(process.env.REACT_APP_API_URL + "/api/brandowner/subscription/get-my-prices")
    setPrices(responsePrices.data)
  }

  useEffect(() => {
    if (userInfo.is_free_user) {
      setLoading(false)
      return
    }
    fetchUser()
      .catch(err => {
        console.log(err)
        setError(true)
      })

    dispatch(fetchMyTeam())
      .catch(err => {
        console.log(err)
        setError(true)
      })

    fetchPrices()
      .catch(err => {
        console.log(err)
        setError(true)
      })
  }, [])

  useEffect(() => {
    if (myTeamStatus === "succeeded") {
      setLoading(false)
    } else {
      setLoading(true)
    }
  }, [myTeamStatus])

  const handleCreateNewSales = () => {
    const newSalesForm = document.getElementById("new-salesforce-form")
    const myTeamTable = document.getElementById("my-team-table")
    const saveButton = document.getElementById("myteam__btn-save")

    newSalesForm.classList.toggle("invisible")
    myTeamTable.classList.toggle("big")
    saveButton.classList.toggle("hide")

    setTableBig(!tableBig)
  }

  const preCheckSaveNewSales = () => {
    showNotificationPopup({
      title: "Are you sure?",
      actionButtonOnClick: () => handleSaveNewSales(),
      actionButtonText: "Yes",
      popupButtonColor: "green"
    })
  }

  const handleSaveNewSales = async () => {
    document.getElementById("myteam__btn-save").disabled = true
    closePopupNotification()

    let okToSave = false

    const email = document.getElementById("myteam__email")
    const password = document.getElementById("myteam__password")
    const first_name = document.getElementById("myteam__first_name")
    const last_name = document.getElementById("myteam__last_name")

    const emailErrorElement = document.getElementById("input_error_email")
    const emailValidation = /\S+@\S+\.\S+/
    emailValidation.test(email.value) ? emailErrorElement.classList.remove("active") : emailErrorElement.classList.add("active")

    const requiredElements = [email, password, first_name, last_name]

    const data = {
      "type": type,
    }

    requiredElements.forEach(element => {
      if (element.value === "") {
        element.classList.add("red-border")
      } else {
        element.classList.remove("red-border")
        data[element.name] = element.value.trim()
      }
    })

    requiredElements.every(element => element.value) &&
      emailValidation.test(email.value) ?
      okToSave = true : okToSave = false

    if (!okToSave) {
      toast.error("Please fill out all fields", { theme: "dark" })

      document.getElementById("myteam__btn-save").disabled = false
      return
    }

    if (okToSave) {
      try {
        const response = await toast.promise(
          httpClient.post(process.env.REACT_APP_API_URL + "/api/brandowner/salesforce/create", data),
          {
            success: "New salesforce created",
            pending: "Creating new salesforce...",
            error: "Something went wrong"
          }
        )
        dispatch(fetchMyTeam())
      } catch (err) {
        console.log(err)
      }
    }

    const inputElements = document.getElementsByClassName("input_field__input_box--text")

    for (let i = 0; i < inputElements.length; i++) {
      inputElements[i].value = ""
    }

    document.getElementById("myteam__btn-save").disabled = false
  }

  const handleHideInactive = () => {
    dispatch(toggleHideInactiveTeamMembers())
  }

  const handleSetActive = async (type, id) => {
    const data = {
      type: type,
      sales_rep_id: id
    }

    try {
      const response = await httpClient.post(process.env.REACT_APP_API_URL + "/api/brandowner/myteam/set-active", data)
      dispatch(fetchMyTeam())
    } catch (err) {
      console.log(err)
    }
  }

  const handlePreCheckDeactivate = (sales_rep_id) => {
    showNotificationPopup({
      title: "Are you sure?",
      actionButtonOnClick: () => handleSetActive("deactivate", sales_rep_id),
      actionButtonText: "Yes",
      popupButtonColor: "green"
    })
  }

  const handlePreCheckReactivate = (sales_rep_id) => {
    showNotificationPopup({
      title: "Are you sure?",
      actionButtonOnClick: () => handleSetActive("reactivate", sales_rep_id),
      actionButtonText: "Yes",
      popupButtonColor: "green"
    })
  }

  const handleChangeType = (type) => {
    setType(type)
  }

  return (
    <div className="template">
      {/* {error ? window.location.href = "/" : null} */}

      <Header>
        {
          userInfo.is_free_user ?
            <></> :
            <>
              <div className="header__options">
                <div className="header__option--slider">
                  <Slider 
                    size={1.2} 
                    text="Hide inactive" 
                    onClickFunction={handleHideInactive} 
                    state={myTeamHideInactive} 
                    defaultState={true} 
                  />
                </div>
              </div>
              <button className="green-button-header hide" id="myteam__btn-save" onClick={preCheckSaveNewSales}>SAVE</button>
            </>
        }
      </Header>

      <MenuBar />

      <NotificationTop />

      <div className="template__body">
        {
          userInfo.is_free_user ?
            <>
              <div className="body__section big" id="free-user-restriction--my-team">
                <img src={noAccess} />
                <div className="body__section_content">
                  <h3>You do not have access to this page</h3>
                  <p>Here you can add new team members to your brand. Create salesforce logins for your sales people. Manage their access to the customer database.</p>
                </div>
              </div>
            </> :
            <>
              <button className="floating__button--bottom-right" id="add--btn" onClick={handleCreateNewSales} />
              <div className="body__my_team">

                <div className="body__section invisible" id="new-salesforce-form">
                  <div className="form__section"><h1>Login details</h1>
                    <div className="input_field">
                      <label className="input_field__label" htmlFor="email">Email</label>
                      <input className="input_field__input_box--text" id="myteam__email" type="text" name="email" />
                    </div>
                    <div className="input_error" id="input_error_email">{emailError}</div>

                    <div className="input_field">
                      <label className="input_field__label" htmlFor="password">Password</label>
                      <input className="input_field__input_box--text" id="myteam__password" type="text" name="password" />
                    </div>
                  </div>

                  <div className="form__section"><h1>Personal details</h1>
                    <div className="input_field">
                      <label className="input_field__label" htmlFor="first_name">First name</label>
                      <input className="input_field__input_box--text" id="myteam__first_name" type="text" name="first_name" />
                    </div>
                    <div className="input_field">
                      <label className="input_field__label" htmlFor="last_name">Last name</label>
                      <input className="input_field__input_box--text" id="myteam__last_name" type="text" name="last_name" />
                    </div>
                  </div>

                  <div className="form__section"><h1>Account details</h1>
                    <div className="input_field">
                      <label className="input_field__label" htmlFor="company_name">Type</label>
                      <Dropdown 
                        listArray={["Salesforce", "Manager"]}
                        onChangeFunction={handleChangeType}
                        inherit={true}
                        state={type}
                        setState={setType}
                        marginTop={"2.5px"}
                      />
                    </div>
                  </div>

                </div>
                <div className="body__section big" id="my-team-table">

                  {
                    loading ? <LoadingSpinner /> :
                      <div className="table" id="myteam--table">
                        <div className="table__header-4">
                          <div className="table__header--field">#</div>
                          <div className="table__header--field">Name</div>
                          <div className="table__header--field">Email</div>
                          <div className="table__header--field">Type</div>
                          <div className="table__header--field">Is active</div>
                          <div className="table__header--field"></div>
                        </div>
                        <div className="table__rows_container">
                          {
                            myTeamRedux && myTeamRedux.map((member, index) => {
                              return (
                                <div className="table__row-4" key={index}>
                                  <div className={`table__row--field ${member.active ? "" : "grayed-out"}`}>{index + 1}</div>
                                  <div className={`table__row--field ${member.active ? "" : "grayed-out"}`}>{member.name}</div>
                                  <div className={`table__row--field ${member.active ? "" : "grayed-out"}`}>{member.email}</div>
                                  <div className={`table__row--field ${member.active ? "" : "grayed-out"}`}>{member.type}</div>
                                  <div className={`table__row--field ${member.active ? "" : "grayed-out"}`}>{member.active ? "Active" : "Inactive"}</div>
                                  <div className="table__row--field">{member.active ? <button className="red-action-button" onClick={() => handlePreCheckDeactivate(member.id)} >Deactivate</button> : <button className="green-action-button" onClick={() => handlePreCheckReactivate(member.id)} >Reactivate</button>}</div>
                                </div>
                              )
                            })
                          }
                        </div>
                      </div>
                  }

                </div>
              </div>
            </>
        }
      </div>
    </div>
  )
}

export default SalesforcePageBrandOwnerMyTeam