import Smartphone from "../assets/pictos/svgs/Smartphone"
import Visit from "./pictos/svgs/Visit"
import Task from "./pictos/svgs/Task"
import Mail from "./pictos/svgs/Mail"
import Meeting from "./pictos/svgs/Meeting"
import Note from "./pictos/svgs/Note"
import Tasting from "./pictos/svgs/Tasting"

export const types_array = {
  "horeca": ["restaurant", "fine dining", "star restaurant", "bar", "bistro", "cocktail bar", "pub", "food pub", "tearoom", "hotel", "catering", "summer bar", "beachbar"],
  "wholesaler": ["N/A"],
  "shop": ["liquor store", "specialty shop", "retail", "concept store"],
  "distributor": ["N/A"],
  "other": ["N/A"],
  "B2C": ["N/A"],
  "": [],
}

export const couriers = ["be-post", "nl-post", "dhl", "dpd", "nl-post-int", "be-post-int"]

const emailPicto = require(`./pictos/email.png`)
const formPicto = require(`./pictos/form.png`)
const phonePicto = require(`./pictos/smartphone.png`)
const visitPicto = require(`./pictos/visit.png`)
const meetingPicto = require(`./pictos/meeting.png`)
const notePicto = require(`./pictos/note.png`)
const orderPicto = require(`./pictos/order.png`)
const tastingPicto = require(`./pictos/tasting.png`)

export const activity_types = {
  "task": { "picto": formPicto },
  "visit": { "picto": visitPicto },
  "tasting": { "picto": tastingPicto },
  "phone call": { "picto": phonePicto },
  "email": { "picto": emailPicto },
  "meeting": { "picto": meetingPicto },
  "note": { "picto": notePicto }
}

export const activity_types_svgs = {
  "task": {
    "picto": <Task/>
  },
  "visit": {
    "picto": <Visit/>
  },
  "tasting": {
    "picto": <Tasting/>
  },
  "phone call": {
    "picto": <Smartphone/>
  },
  "email": {
    "picto": <Mail/>
  },
  "meeting": {
    "picto": <Meeting/>
  },
  "note": {
    "picto": <Note/>
  }
}

export const customerContactRhythmsArray = ["Yearly", "Semi-annual", "Quarterly", "Monthly", "Weekly", "Daily"]
