import React, { useEffect, useRef, useState } from "react"
import { useSelector, useDispatch } from "react-redux"
import "./DropDownFilter.css"
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import ClearIcon from '@mui/icons-material/Clear'

let key = 0

function DropDownFilter({ idList, value, dropdownType, placeholderText, filter, reduxState, setValue, listArray, searchType, onClickItem, widthSize, clearOption, innerTitle, searchBar }) {
  const [searchValue, setSearchValue] = useState("")
  const [localValue, setLocalValue] = useState(placeholderText)
  const [dropdownIsOpen, setDropdownIsOpen] = useState(false)

  const dispatch = useDispatch()
  const filtersCustomerPage = useSelector(state => state.customerPage.filters)
  const filtersSalesPageBrandowner = useSelector(state => state.salesPageBrandOwner.filters)
  const filtersSalesPipePage = useSelector(state => state.salesPipePage.filters)
  const filtersPerformancePage = useSelector(state => state.performancePage.filters)

  const ref = useRef(null)
  const textFieldRef = useRef(null)

  const onClickOutside = () => {
    setDropdownIsOpen(false)
  }

  useEffect(() => {
    
    if (typeof value == "string") {
      if (!value || value == "") {
        setLocalValue(placeholderText)
      } else {
        setLocalValue(value)
      }
    } else if (typeof value == "object") {
      if (!value?.name || value?.name == "") {
        setLocalValue(placeholderText)
      } else {
        setLocalValue(value?.name)
      }
    } else {
      console.log("value", value)
      setLocalValue(placeholderText)
    }
  }, [value])

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        onClickOutside && onClickOutside()
      }
    }
    document.addEventListener("click", handleClickOutside, true)

    return () => {
      document.removeEventListener("click", handleClickOutside, true)
    }
  }, [onClickOutside])

  const handleClickDropdown = (event) => {
    setDropdownIsOpen(!dropdownIsOpen)

    if (searchBar) {
      const searchBar = document.getElementById(`search-bar--${idList}`)
      searchBar.focus()
    }
  }

  const clearValues = (event) => {
    event.stopPropagation()
    let updatedFilters = {}
  
    if (!filter) {
      onClickItem(event, '', idList)
    } else {
      switch (dropdownType) {
        case "salesPipePage":
          updatedFilters = { ...filtersSalesPipePage }
          break
        case "salesPageBrandowner":
          updatedFilters = { ...filtersSalesPageBrandowner }
          break
        case "performancePage":
          updatedFilters = { ...filtersPerformancePage }
          break
        default:
          updatedFilters = { ...reduxState }
          break
      }
  
      if (typeof filter === 'string') {
        updatedFilters = { ...updatedFilters, [filter]: "" }
      } else if (typeof filter === 'object') {
        updatedFilters = { ...updatedFilters, [filter]: { ...updatedFilters[filter], name: "" } }
      }
      dispatch(setValue(updatedFilters))
    }
  
    setLocalValue(placeholderText)
  }

  const onClick = (e, data, dataType) => {
    onClickItem(e, data, idList)

    setDropdownIsOpen(false)
  }

  return (
    <div key={localValue} className={`dropdown__container flex--vert ${widthSize} ${localValue && localValue !== placeholderText ? "filter_active" : ""}`} ref={ref}>
      <span className="input_field__input_box--text filter" onClick={handleClickDropdown}>

        <p className="dropdown-input--text_field" ref={textFieldRef}>
          <b>{innerTitle}</b>
          {localValue}
        </p>
        {
          clearOption ?
            <>
              {localValue == "" || localValue == placeholderText ? <KeyboardArrowDownIcon /> : <ClearIcon onClick={clearValues} />}
            </> :
            <>
              <KeyboardArrowDownIcon />
            </>
        }

      </span>
      <ul className={`input_field__dropdown ${dropdownIsOpen ? "active" : ""}`} id={idList}>
        {
          searchBar &&
          <input className="search-bar" id={`search-bar--${idList}`} placeholder="Search..." value={searchValue} onChange={event => setSearchValue(event.currentTarget.value)} autoComplete="off" />
        }
        {
          Array.isArray(listArray) && listArray
            .filter(item => {
              if (searchType == "startsWith") {
                if (typeof item === "object") {
                  return item.name.toLowerCase().startsWith(searchValue.toLowerCase())
                }

                if (typeof item === "string") {
                  return item.toLowerCase().startsWith(searchValue.toLowerCase())
                }
              }

              if (typeof item === "object") {
                return item.name?.toLowerCase().includes(searchValue.toLowerCase())
              }

              if (typeof item === "string") {
                return item.toLowerCase().includes(searchValue.toLowerCase())
              }
            })
            .map((item, index) => {
              if (typeof item === "string") {
                return <li key={index} onClick={(e) => onClick(e, item, "string")}>{item}</li>
              }

              if (typeof item === "object") {
                return <li key={index} data-item={JSON.stringify(item)} onClick={(e) => onClick(e, item, "object")}>{item.name}</li>
              }
            })
        }
      </ul>
    </div>
  )
}

export default DropDownFilter

DropDownFilter.defaultProps = {
  clearOption: true,
  searchBar: true
}